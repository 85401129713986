<template>
  <div class="page-container">
    <div class="login-ctan">
      <img src="@/assets/images/login_head.png" alt="" class="head-img" />
      <el-form ref="form" :model="form" class="login-form">
        <el-form-item>
          <el-input v-model="form.username" placeholder="用户名">
            <template #prefix>
              <i class="glyphicon glyphicon-user"></i>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.password" type="password" placeholder="密码">
            <template #prefix>
              <i class="glyphicon glyphicon-lock"></i> </template
          ></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-checkbox-group v-model="form.keeplogin">
            <el-checkbox value="1" label="1">保持会话</el-checkbox>
          </el-checkbox-group>
        </el-form-item> -->
        <el-form-item>
          <el-button
            :loading="loading"
            type="primary"
            size="large"
            class="btn-login"
            @click="loginHandle"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 备案信息 -->
    <div class="beian">
      <img src="../../../assets/images/gongan.png" />
      <P @click="navigateBeian" style="cursor: pointer; margin-left: 5px"
        >沪ICP备14023445号-2</P
      >
    </div>
  </div>
</template>

<script>
import { login } from "@/apis/common";
import http from "@/helpers/http";
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
        keeplogin: [],
      },
      loading: false,
    };
  },
  created() {
    window.http = http;
  },
  mounted() {},
  methods: {
    loginHandle() {
      if (!this.form.username) {
        this.$message.error("用户名不能为空");
        return;
      }
      if (!/^\w{3,12}$/.test(this.form.username)) {
        this.$message.error("请填写3-12位数字、字母、下划线");
        return;
      }
      if (!this.form.password) {
        this.$message.error("密码不能为空");
        return;
      }
      if (!/^[\S]{6,16}$/.test(this.form.password)) {
        this.$message.error("请填写6-16位字符，不能包含空格");
        return;
      }

      this.loading = true;
      login(this.form)
        .then(async (res) => {
          console.log();
          if (res.code === "0000") {
            await this.$store.dispatch("loginSuccess", res.result);
            this.$router.push({ path: "/" });
          } else {
            this.$message.error(res.message || "登录失败");
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    /**
     * 跳转备案网站
     */
    navigateBeian() {
      window.open("http://www.beian.gov.cn/portal/recordQuery", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@use "sass:math";
.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: url("../../../assets/images/login_bg.jpg") no-repeat;
  background-size: cover;
  background-position: left top;

  .login-ctan {
    width: 430px;
    border-radius: 3px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 10%);
    background: rgba(255, 255, 255, 1);
    border: none;
    overflow: hidden;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin: 0 35px;

    .head-img {
      background: #899fe1;
      width: 100%;
      display: block;
    }

    .login-form {
      padding: 40px 30px;
      margin-top: 50px;

      .btn-login {
        width: 100%;
        height: 40px;
        background: #708eea;
        border-color: #708eea;
      }
      .btn-login:hover {
        background: mix(#ffffff, #708eea, math.percentage(math.div(3, 10)));
        border-color: mix(#ffffff, #708eea, math.percentage(math.div(3, 10)));
      }
    }
  }

  .beian {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    height: 50px;
    width: 100%;
    color: white;
    margin: 20px 0;
    place-items: center;
  }
}
</style>
